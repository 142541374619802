import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout"
import Seo from "../components/SEO"
import Title from "../components/Title"

const Membership = ({ data }) => {
  const image = getImage(data.hero)
  const imageSupport = getImage(data.support)

  return (
    <Layout>
      <Seo
        title="Membership"
        desc="Become a member of Vietnamese Guide and enjoy these perks."
      />
      <div className="half hero">
        <div className="left-half">
          <Title title="Become a member" />
          <p>
            For just a few dollars per month, help support us by becoming a
            member on{" "}
            <strong>
              <a
                href="https://www.patreon.com/vietnameseguide"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Patreon.
              </a>
            </strong>
          </p>
          <p>
            Membership perks include podcast transcripts, vocabulary helpers,
            audio clips for practicing pronunciation, worksheets, and other
            bonus content.
          </p>
          <a
            href="https://www.patreon.com/vietnameseguide"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <GatsbyImage image={imageSupport} alt="Become a patron" />
          </a>
        </div>
        <div className="desktop-only">
          <GatsbyImage image={image} alt="Vietnamese Guide" />
        </div>
      </div>
    </Layout>
  )
}

export default Membership

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "membership.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
    support: file(relativePath: { eq: "patron_button.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`
