import React from "react"

const Title = ({ title }) => {
  return (
    <div>
      <h2 className="underline">{title || "default title"}</h2>
    </div>
  )
}

export default Title
